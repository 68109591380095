.theme {
  background: var(--color-background);
  color: var(--color-text);
  display: grid;
  align-items: start;
  min-height: 100vh;
  &[data-theme='dark'] {
    --color-background: var(--color-text);
    --color-text: var(--color-background);
  }
}